<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Sales</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-4"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Sales')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false" eager>
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <SaleTypesField
                  dense
                  v-model="filters.saleTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Sale Types')"
                  clearable
                  @input="onSubmit"
                ></SaleTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="[
                    { text: 'Any', value: null },
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  v-model="filters.eligibleForPayoutGeneration"
                  :label="$i18n.translate('Eligible For Payout Generation?')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filters.isEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
      >
        <template v-slot:item.saleKey="{ item }">
          <router-link :to="{ name: 'sale', params: { id: item.id } }">
            {{ item.saleKey }}
          </router-link>
        </template>
        <template v-slot:item.saleDate="{ item }">
          {{ item.saleDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </template>
        <template v-slot:item.eligibleForPayoutGeneration="{ item }">
          {{ item.eligibleForPayoutGeneration ? "Yes" : "No" }}
        </template>
        <template v-slot:item.updatedDate="{ item }">
          {{ item.updatedDate | formatDateFromNow }}
        </template>
        <template v-slot:item.customer="{ item }">
          <span v-if="item.firstName && item.lastName">
            {{ item.firstName }}
            {{ item.middleName ? item.middleName : "" }}
            {{ item.lastName }}
          </span>
          <span v-if="item.companyName"><br />{{ item.companyName }}</span>
          <span v-if="item.address"><br />{{ item.address.fullAddress }}</span>
        </template>
        <template v-slot:item.effective="{ item }">
          <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
        <template v-slot:item.calculatedTotalPayoutPaidAmount="{ item }">
          <span v-if="item.calculatedTotalPayoutPaidAmount">
            {{ item.calculatedTotalPayoutPaidAmount | toNumber(2) }}
            {{ item.currency.name }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import SaleTypesField from "../../fields/SaleTypesField.vue";

export default {
  components: { SaleTypesField },
  name: "Sales",
  metaInfo: {
    title: "Sales"
  },
  data() {
    return {
      loading: false,
      search: "",
      filters: {
        eligibleForPayoutGeneration: true,
        isEffective: "Active"
      },
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      items: [],
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.filterBySaleTypes = [];
      this.filterByPromotionTypes = [];
      this.onSubmit();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let f = Object.assign({}, this.filters);

      if (this.search && this.search.trim().length > 0) {
        f.keyword = this.search.trim();
      }

      if (f.saleTypes && f.saleTypes.length > 0) {
        console.log(f.saleTypes.length);
        f.saleTypes = f.saleTypes.map(saleType => {
          return {
            id: saleType.id
          };
        });
      } else {
        delete f.saleTypes;
      }

      return this.$api
        .post(
          "/api/sales/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" +
                sortBy[0] +
                "," +
                (sortDesc && sortDesc.length > 0 && sortDesc[0] ? "DESC" : "ASC") +
                (sortBy && sortBy.length > 0 && sortBy[0] == "saleStage.name"
                  ? "&nestedSort=saleStage.name," + (sortDesc[0] ? "DESC" : "ASC")
                  : "")
              : ""),
          f
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.headers = [
      {
        value: "saleKey",
        text: this.$i18n.translate("Sale Number"),
        sortable: true
      },
      {
        value: "saleDate",
        text: this.$i18n.translate("Sale Date"),
        sortable: true
      },
      {
        value: "saleType.name",
        text: this.$i18n.translate("Sale Type"),
        sortable: true
      },
      {
        value: "participant.fullName",
        text: this.$i18n.translate("Participant"),
        sortable: true
      },
      {
        value: "organization.name",
        text: this.$i18n.translate("Organization"),
        sortable: true
      },
      {
        value: "calculatedTotalPayoutPaidAmount",
        text: "Total Paid",
        align: "right",
        sortable: true
      },
      {
        value: "eligibleForPayoutGeneration",
        text: "Eligible For Payouts?",
        sortable: true,
        align: "center"
      },
      {
        value: "customer",
        text: this.$i18n.translate("Customer"),
        align: "right",
        sortable: true
      },
      {
        value: "effective",
        text: "Status",
        align: "center"
      },
      {
        value: "updatedDate",
        text: "Last Updated",
        sortable: true
      }
    ];
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      { text: "Sales" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  }
};
</script>
